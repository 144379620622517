import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'remedyBatchDetails',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
  },
  props: ['remedyHdrData','auditData' ,'batchId', 'eventId','remStatus' ,'assetRemedyDetails','locAccessFlag'],
  watch: {
    currentPage: function() {
      //   this.getassetMasterItems();
    },
    remStatus: function() {
      this.getRemedyHeaderDetails()
    },
    perPage: function() {
      this.currentPage = 1;
      //   this.getassetMasterItems();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      assetRemedyBatchData: [  
    ],
    assetRemedyBatchFields: [
        {
          key: 'asset_num',
          label:'Asset Number'
        },
        {
          key: 'serial_num',
          label:'Serial Number'
        },
        {
          key: 'asset_type',
        },
        {
          key: 'status',
        },
        {
          key: 'created_by',
        },
        {
            key:'bin_combination_logical',
            label:'Logical Assets'
        },
        {
            key:'bin_combination_physical',
            label:'Physical Assets'
        },
        {
          key: 'physical_location_name',
        },
        {
          key: 'logical_location_name',
        },
        {
          key:'action_meaning',
          label:'Action',
        },
        {
          key: 'remarks',
        }
      ],
      approvalHistoryData: [],
      approvalHistoryFields: [
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'action_date'
        },
        {
          key: 'seq_order',
          label: 'Approver Order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments'
        }
      ],
      selectAllCheckBox: false,
      editMode: false,
      showValueSetModal:false,
      remedyBatchNo:null,
      approvalStatus:null,
      auditBatchNo:null,
      createdBy:null,
      allFlag: false,
      wfDtlId:null,
      showApproveModal:false,
      showRejectModal:false,
      approvalReason:null,
      rejectedReason:null,
      remedyBatchId:null,
      showHideRemedyAssetsModalFlag:false,
      addAssetRemedyBatchData: [  
      ],
      addAssetRemedyBatchFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
          {
            key: 'asset_number',
            label:'Asset Number'
          },
          {
            key: 'serial_no',
            label:'Serial Number'
          },
          {
            key: 'asset_type',
          },
          {
            key: 'status',
          },
          {
            key: 'created_by',
          },
          {
              key:'logical_assets',
              label:'Logical Assets'
          },
          {
              key:'physical_assets',
              label:'Physical Assets'
          },
          {
            key: 'physical_loc_name',
            label: 'Physical Location Name',
          },
          {
            key: 'logical_loc_name',
            label: 'Logical Location Name',
          },
          {
            key:'action_meaning',
            label:'Action',
          },
          {
            key:'action',
            class: 'd-none'
          },
          {
            key:'remarks'
          }
        ],
        auditHdrId:null,
        accessButtons:[],
        selectedRow:[],
        specIndex:null,
    };
  },
  validations: {},
  mounted() {
  if(this.assetRemedyDetails){
    this.getRemedyBatchDetails(this.assetRemedyDetails.remedy_batch_id)
    this.getRemedyHeaderDetails(this.assetRemedyDetails.remedy_batch_id)
    this.wfDtlId=this.assetRemedyDetails.wf_inprocess_dtl_id;
  } else{
    this.getRemedyBatchDetails(this.remedyHdrData.remedy_batch_id)
    this.getRemedyHeaderDetails(this.remedyHdrData.remedy_batch_id)  
  }
  this.getHistory()
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.showHideRemedyAssetsModalFlag) {
            // this.postRemedy();
            this.postAssetsToRemedyBatch()
        } else if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getRemedyBatchDetails(item) {
        const payload = {
        BatchID: item
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getRemedyBatchDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
                this.assetRemedyBatchData=resp.data.data.page
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      getRemedyHeaderDetails(item) {
        const payload = {
        BatchID: item
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getRemedyDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
                this.remedyBatchNo=resp.data.data[0].remedy_batch_number
                this.approvalStatus=resp.data.data[0].approval_status
                this.auditBatchNo=resp.data.data[0].audit_batch_number
                this.createdBy=resp.data.data[0].created_by  
                this.remedyBatchId=resp.data.data[0].remedy_batch_id 
                this.auditHdrId=resp.data.data[0].audit_hdr_id                            
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      switchVariable() {
        this.allFlag = this.allFlag
          ? (this.allFlag = false)
          : this.allFlag == false
          ? (this.allFlag = true)
          : null;
        this.getHistory();
      },
      getHistory() {
        const payload = {
          eventId: this.eventId,
          nfaHdrId: this.batchId ?this.batchId: this.assetRemedyDetails.remedy_batch_id,
          approvalStatus: this.allFlag ? 'Y' : !this.allFlag ? 'N' : ''
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getApprovalList', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.approvalHistoryData = response.data.data;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      showHideApproveModal(flag) {
        this.showApproveModal = flag;
      if (!flag) {
        this.approvalReason = null;
      }
    },
    showHideRejectModal(flag) {
      this.showRejectModal = flag;
      if (!flag) {
        this.rejectedReason = null;
      }
    },
    approveRejectInvoiceApproval(status) {
      const payload = {
        event_id: this.eventId,
        wf_inprocess_dtl_id: this.wfDtlId,
        notification_status: status,
        notification_comments:
          status === 'REJECTED' ? this.rejectedReason : this.approvalReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approvalRejectSingle', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (status === 'REJECTED') {
              this.showHideRejectModal(false);
            } else if (status === 'APPROVED') {
              this.showHideApproveModal(false);
            }
            this.$emit('updateRemedyDetails');
          } else {
            this.showAlert = false;
            alert(response.response.data.message);
            // this.isSuccess = false;
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRowRemedy(){
      const payload = {
        remedy_id:this.remedyBatchId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/scrapRemedyBatch', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true; 
            this.responseMsg = resp.data.message;
            // this.editMode = false;
            this.$emit('getRemedyHeader')
            this.$emit('getAuditData')
            this.$emit('showHideRemedyBatchModal',true,"SCRAP");
            if(this.assetRemedyDetails){
              this.getRemedyBatchDetails(this.assetRemedyDetails.remedy_batch_id)
              this.getRemedyHeaderDetails(this.assetRemedyDetails.remedy_batch_id)
              this.wfDtlId=this.assetRemedyDetails.wf_inprocess_dtl_id;
            } else{
              this.getRemedyBatchDetails(this.remedyHdrData.remedy_batch_id)
              this.getRemedyHeaderDetails(this.remedyHdrData.remedy_batch_id)  
            }
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        }); 
      },
      addRemedyAssets(flag){
        this.showHideRemedyAssetsModalFlag=flag
        if(flag){
            this.getAssetMatchingAssets()
        }else{
          if(this.assetRemedyDetails){
            this.getRemedyBatchDetails(this.assetRemedyDetails.remedy_batch_id)
            this.getRemedyHeaderDetails(this.assetRemedyDetails.remedy_batch_id)
            this.wfDtlId=this.assetRemedyDetails.wf_inprocess_dtl_id;
          } else{
            this.getRemedyBatchDetails(this.remedyHdrData.remedy_batch_id)
            this.getRemedyHeaderDetails(this.remedyHdrData.remedy_batch_id)  
          }
        }
        if(flag && !this.locAccessFlag) {
          this.accessButtons = {
            deleteAllow: false,
            downloadAllow: false,
            uploadAllow: false,
            editAllow: false,
            addAllow: false,
            printAllow: false,
            approvalAllow: false
          };
        }else{
          this.accessButtons = {
            deleteAllow: false,
            downloadAllow: false,
            uploadAllow: false,
            editAllow: false,
            addAllow: true,
            printAllow: false,
            approvalAllow: false
          };
        }
      },
      getAssetMatchingAssets() {
        const payload = {
          audit_hdr_id:this.auditHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getAuditScrapedData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.addAssetRemedyBatchData = response.data.data.page;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      selectAllBoxChecked(flag) {
        this.selectedRow = [];
        if (flag) {
          this.addAssetRemedyBatchData = this.addAssetRemedyBatchData.map((data) => {
              data.selectBox = this.selectAllCheckBox;
            return data;
          });
          this.assetPutAwayData.map(ele=>{
            this.selectedRow.push(ele)
          });
        } else {
          this.addAssetRemedyBatchData = this.addAssetRemedyBatchData.map((data) => {
            data.selectBox = this.selectAllCheckBox;
            return data;
          });
          this.selectedRow = [];
        }
      },
      selectBoxChecked(flag, index,item,assetNum) {
        this.addAssetRemedyBatchData[index].selectBox = flag;
        if (flag === true) {
          this.selectedRow.push(item);
        } else {
          this.selectedRow = this.selectedRow.filter(
            el => el.asset_number !== assetNum
          );
 
        }
      },
      postAssetsToRemedyBatch(){
        const payload = {
          audit_hdr_id: this.auditHdrId,
          remedy_dtls:[]
        };
        this.addAssetRemedyBatchData.map(ele => {
          if (ele.selectBox) {
            payload.remedy_dtls.push({
              asset_id: ele.asset_id,
              action: ele.action,
              remarks:ele.remarks?ele.remarks:'' 
            });
            if(ele.action==null){
              payload.remedy_dtls=[]
              return
            }
          }      
        });
        if(payload.remedy_dtls.length){
          this.loader = true;
          this.$store
            .dispatch('assets/postAddAssetToExistingRemedy', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.showAlert = true;
                (this.isSuccess = true);
                (this.responseMsg = resp.data.message);
                this.totalRows = resp.data.data.total_elements;
                this.addAssetRemedyBatchData.map(ele=>{
                  ele.selectBox=false
                })
 
                this.selectAllCheckBox=false;
                this.getAssetMatchingAssets()
              }
              else{
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = resp.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
        else{
          this.$bvToast.toast("Kindly select a record first or fill the mandatory field", {
            title: 'Alert',
            variant: 'danger',
            solid: true,
          });
        }
      },
      openValueSetModal(vsetCode, index) {
        this.vsetCode = vsetCode;
        this.showValueSetModal = true;
        this.specIndex = index;
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },
      selectedvalueSet(item) {
        if (this.vsetCode === appStrings.VALUESETTYPE.REMEDY_ACTION) {
          this.addAssetRemedyBatchData[this.specIndex].action_meaning = item.value_meaning;
            this.addAssetRemedyBatchData[this.specIndex].action =item.value_code;
            setTimeout(() => {
              this.addAssetRemedyBatchData = JSON.parse(
                JSON.stringify(this.addAssetRemedyBatchData)
              );
            }, 0);
        }
      },
      closeValueSetModal() {
        this.showValueSetModal = false;
      },
 
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
